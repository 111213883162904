import { defineStore } from 'pinia'
import type { Redirect, Site } from '#payload/types'
import { GetGlobalsDocument } from '@/graphql/payload'

interface GlobalsQuery {
  Redirect: Redirect
  Site: Site
}

export const useGlobalsStore = defineStore('globals', () => {
  const redirects = ref<Redirect | null>(null)
  const site = ref<Site | null>(null)

  const loadGlobals = () => {
    return useAsyncQuery<GlobalsQuery>({
      query: GetGlobalsDocument,
      clientId: 'payload',
    }).then(({ data }) => {
      redirects.value = data.value?.Redirect || null
      site.value = data.value?.Site || null
    })
  }

  return {
    redirects,
    site,
    loadGlobals,
  }
})
