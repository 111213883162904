<template>
  <svg width="0" height="0" viewBox="0 0 400 400">
    <defs>
      <clipPath id="cloud-mask-double" clipPathUnits="objectBoundingBox">
        <circle cx="0.306121019" cy="0.306121019" r="0.306121019" />
        <circle cx="0.693878981" cy="0.306121019" r="0.306121019" />
        <circle cx="0.306121019" cy="0.693878981" r="0.306121019" />
        <circle cx="0.693878981" cy="0.693878981" r="0.306121019" />
      </clipPath>
      <clipPath id="cloud-mask-triple" clipPathUnits="objectBoundingBox">
        <circle cx="0.235" cy="0.235" r="0.235" />
        <circle cx="0.765" cy="0.235" r="0.235" />
        <circle cx="0.5" cy="0.235" r="0.235" />
        <circle cx="0.235" cy="0.765" r="0.235" />
        <circle cx="0.765" cy="0.765" r="0.235" />
        <circle cx="0.5" cy="0.765" r="0.235" />
        <circle cx="0.235" cy="0.5" r="0.235" />
        <circle cx="0.765" cy="0.5" r="0.235" />
        <circle cx="0.5" cy="0.5" r="0.235" />
      </clipPath>
    </defs>
  </svg>
</template>
