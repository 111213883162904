export const useRelationshipField = <Collection>(
  field: MaybeRef<string | Collection | null | undefined>,
) => {
  const doc = ref<Collection | null>(null) as Ref<Collection | null>

  watchEffect(() => {
    const fieldUnref = unref(field)

    doc.value = fieldUnref && typeof fieldUnref !== 'string' ? fieldUnref : null
  })

  return doc
}
