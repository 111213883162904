import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import nl2br from 'nl2br'
import type { MoneyBag } from '@/shopify-admin'

/* eslint-disable import/no-named-as-default-member */
dayjs.extend(utc)
dayjs.extend(timezone)
/* eslint-enable import/no-named-as-default-member */

export default defineNuxtPlugin(() => {
  const helpers = {
    dayjs,
    formatPrice(price: string | number, trim = true) {
      const parsedPrice = parseFloat(
        typeof price === 'number' ? price.toString() : price,
      )

      return parsedPrice > 0
        ? `$${parsedPrice
            .toLocaleString('en-AU', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
            .replace(trim ? '.00' : '', '')}`
        : 'Free'
    },
    formatMoneyBag(moneyBag: MoneyBag) {
      return this.formatPrice(moneyBag.shopMoney.amount)
    },
    nl2br,
    pluralise(number: Number, word: String, plural = `${word}s`) {
      return `${number} ${number === 1 ? word : plural}`
    },
  }

  return { provide: { helpers } }
})
