<template>
  <div class="relative">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <DevGridOverlay />
    <SvgCloudMasks />
  </div>
</template>

<script lang="ts" setup>
import { init as initFullStory } from '@fullstory/browser'
import type { UseSeoMetaInput } from '@unhead/vue'

import { theme } from '#tailwind-config'

const config = useRuntimeConfig()
const accountStore = useAccountStore()
const globalsStore = useGlobalsStore()

await Promise.all([
  accountStore.loadCustomerAccessTokenCookie(),
  globalsStore.loadGlobals(),
])

/**
 * SEO / Meta
 */
const seoMeta: UseSeoMetaInput = {
  ogImageWidth: 1200,
  ogImageHeight: 630,
  twitterCard: 'summary_large_image',
}

seoMeta.title =
  seoMeta.ogTitle =
  seoMeta.twitterTitle =
    globalsStore.site?.meta?.title || config.public.siteName

seoMeta.description =
  seoMeta.ogDescription =
  seoMeta.twitterDescription =
    globalsStore.site?.meta?.description || ''

const opengraphImage = useRelationshipField(globalsStore.site?.meta?.image)

if (opengraphImage.value?.sizes?.opengraph?.url) {
  seoMeta.ogImage = opengraphImage.value.sizes.opengraph.url
}

useSeoMeta(seoMeta)

const instagramProfile = useInstagramProfile()
useSchemaOrg([
  defineOrganization({
    name: 'Nimbus',
    logo: '/favicon/android-chrome-512x512.png',
    sameAs: [instagramProfile.url],
  }),
  defineWebSite({
    name: 'Drink Nimbus',
  }),
  defineWebPage(),
])

/**
 * Tracking
 */
onMounted(() => {
  if (import.meta.client) {
    initFullStory({
      orgId: config.public.fullStory.organisationId,
      devMode: process.dev,
    })

    if (accountStore.customerAccessToken && !accountStore.customer) {
      accountStore.unsetCustomerAccessTokenCookie()
    }

    accountStore.identifyUser()
  }
})

/**
 * Head / Favicon
 */
const title = globalsStore.site?.meta?.title || config.public.siteName
const themeColour = theme.colors.purple

const route = useRoute()

useHead({
  titleTemplate(titleChunk) {
    return titleChunk ? `${titleChunk} | ${title}` : `${title}`
  },
  bodyAttrs: {
    class:
      'min-h-full font-body antialiased bg-cream-light md:selection:bg-black/10',
  },
  htmlAttrs: {
    class: 'h-full',
    lang: config.public.language,
  },
  meta: [
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
    },
    { name: 'apple-mobile-web-app-title', content: title },
    { name: 'msapplication-TileColor', content: themeColour },
    { name: 'msapplication-config', content: '/browserconfig.xml' },
    { name: 'theme-color', content: theme.colors.cream.light },
    {
      name: 'facebook-domain-verification',
      content: 'f28iui2rltx9blb0oochigb6mojhif',
    },
  ],
  link: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicon/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png',
    },
    { rel: 'manifest', href: '/site.webmanifest' },
    {
      rel: 'mask-icon',
      href: '/favicon/safari-pinned-tab.svg',
      color: themeColour,
    },
    {
      rel: 'shortcut icon',
      href: '/favicon/favicon.ico',
    },
  ],
  script: [
    {
      src: `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${config.public.klaviyo.publicApiKey}`,
      type: 'text/javascript',
      async: true,
    },
    {
      src: `https://t.drinknimbus.co/v1/lst/universal-script?ph=047570bc222428de483467cebd27275953b6ce1bfc73617c49b12ff2c7874ebc&tag=!clicked&origin=SHOPIFY&ref_url=${config.public.siteUrl}${route.fullPath}`,
      type: 'text/javascript',
      async: true,
    },
  ],
})
</script>
