import { default as _91slug_93AB1z3AQvKnMeta } from "/var/www/nimbus-production/apps/nuxt/pages/[slug].vue?macro=true";
import { default as _91shortId_936Px6T5np5gMeta } from "/var/www/nimbus-production/apps/nuxt/pages/account/orders/[shortId].vue?macro=true";
import { default as index9BUOGKOch9Meta } from "/var/www/nimbus-production/apps/nuxt/pages/account/orders/index.vue?macro=true";
import { default as settingslaeMdo3fLAMeta } from "/var/www/nimbus-production/apps/nuxt/pages/account/settings.vue?macro=true";
import { default as _91subscriptionId_935yvZHPnbSMMeta } from "/var/www/nimbus-production/apps/nuxt/pages/account/subscriptions/[subscriptionId].vue?macro=true";
import { default as indexi5vvEAkcjSMeta } from "/var/www/nimbus-production/apps/nuxt/pages/account/subscriptions/index.vue?macro=true";
import { default as accountBF9UN8doOtMeta } from "/var/www/nimbus-production/apps/nuxt/pages/account.vue?macro=true";
import { default as activatevHF4ljOPsqMeta } from "/var/www/nimbus-production/apps/nuxt/pages/activate.vue?macro=true";
import { default as indexHsWEhl149XMeta } from "/var/www/nimbus-production/apps/nuxt/pages/collections/[collectionHandle]/index.vue?macro=true";
import { default as allN3RqzdL9xrMeta } from "/var/www/nimbus-production/apps/nuxt/pages/collections/all.vue?macro=true";
import { default as indexIWJo6AoEMwMeta } from "/var/www/nimbus-production/apps/nuxt/pages/collections/index.vue?macro=true";
import { default as faqsl3sCPcPKYCMeta } from "/var/www/nimbus-production/apps/nuxt/pages/faqs.vue?macro=true";
import { default as indexbUD4mVLEpkMeta } from "/var/www/nimbus-production/apps/nuxt/pages/index.vue?macro=true";
import { default as log_45in1ChY8xA4kIMeta } from "/var/www/nimbus-production/apps/nuxt/pages/log-in.vue?macro=true";
import { default as missiondrg1beVHNjMeta } from "/var/www/nimbus-production/apps/nuxt/pages/mission.vue?macro=true";
import { default as _91productHandle_93fgPyMdW5d8Meta } from "/var/www/nimbus-production/apps/nuxt/pages/products/[productHandle].vue?macro=true";
import { default as _91slug_93yD227mQ36zMeta } from "/var/www/nimbus-production/apps/nuxt/pages/promos/[slug].vue?macro=true";
import { default as _91slug_935Hdw1YjZzIMeta } from "/var/www/nimbus-production/apps/nuxt/pages/qr/[slug].vue?macro=true";
import { default as reset_45passwordZYeZbMER5hMeta } from "/var/www/nimbus-production/apps/nuxt/pages/reset-password.vue?macro=true";
import { default as set_45token90Sus6prieMeta } from "/var/www/nimbus-production/apps/nuxt/pages/set-token.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: accountBF9UN8doOtMeta || {},
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-orders-shortId",
    path: "orders/:shortId()",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/account/orders/[shortId].vue").then(m => m.default || m)
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "settings",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions-subscriptionId",
    path: "subscriptions/:subscriptionId()",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/account/subscriptions/[subscriptionId].vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions",
    path: "subscriptions",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/account/subscriptions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "activate",
    path: "/activate",
    meta: activatevHF4ljOPsqMeta || {},
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: "collections-collectionHandle",
    path: "/collections/:collectionHandle()",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/collections/[collectionHandle]/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-all",
    path: "/collections/all",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/collections/all.vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    meta: indexIWJo6AoEMwMeta || {},
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "log-in",
    path: "/log-in",
    meta: log_45in1ChY8xA4kIMeta || {},
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/log-in.vue").then(m => m.default || m)
  },
  {
    name: "mission",
    path: "/mission",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/mission.vue").then(m => m.default || m)
  },
  {
    name: "products-productHandle",
    path: "/products/:productHandle()",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/products/[productHandle].vue").then(m => m.default || m)
  },
  {
    name: "promos-slug",
    path: "/promos/:slug()",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/promos/[slug].vue").then(m => m.default || m)
  },
  {
    name: "qr-slug",
    path: "/qr/:slug()",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/qr/[slug].vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordZYeZbMER5hMeta || {},
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "set-token",
    path: "/set-token",
    component: () => import("/var/www/nimbus-production/apps/nuxt/pages/set-token.vue").then(m => m.default || m)
  }
]