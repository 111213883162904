import { defineStore } from 'pinia'

import type {
  CartFragment,
  CartLineFragment,
  CartCreateMutation,
  CartCreateMutationVariables,
  CartLinesAddMutation,
  CartLinesAddMutationVariables,
  CartLinesRemoveMutation,
  CartLinesRemoveMutationVariables,
  CartLinesUpdateMutation,
  CartLinesUpdateMutationVariables,
  CartBuyerIdentityUpdateMutation,
} from '@/shopify-storefront'

import {
  CartBuyerIdentityUpdateDocument,
  CartCreateDocument,
  CartLinesAddDocument,
  CartLinesRemoveDocument,
  CartLinesUpdateDocument,
} from '@/graphql/shopify/storefront'

const shopifyCartLinesToHyrosCartLines = ({
  cost,
  merchandise,
  quantity,
}: CartLineFragment) => {
  let name = merchandise.product.title

  if (merchandise.title !== 'Default Title') {
    name += ` – ${merchandise.title}`
  }

  return {
    name,
    price: parseFloat(cost.totalAmount.amount),
    externalId: merchandise.id,
    quantity,
    sku: merchandise.sku,
  }
}

export const useCartStore = defineStore(
  'cart',
  () => {
    const { $fb } = useNuxtApp()
    const cartId = ref('')
    const isLoading = ref(false)
    const showCart = ref(false)

    const accountStore = useAccountStore()

    const createCart = (input?: CartCreateMutationVariables['input']) => {
      isLoading.value = true

      const { mutate, onDone } = useMutation<CartCreateMutation>(
        CartCreateDocument,
        { clientId: 'shopify' },
      )

      onDone(({ data }) => {
        if (data?.cartCreate?.cart) {
          cartId.value = data.cartCreate.cart.id
        }

        isLoading.value = false
      })

      return mutate({
        input: {
          ...input,
          buyerIdentity: {
            countryCode: 'AU',
            ...(accountStore.customerAccessToken && {
              customerAccessToken: accountStore.customerAccessToken,
            }),
          },
        },
      })
    }

    const addCartLines = (lines: CartLinesAddMutationVariables['lines']) => {
      isLoading.value = true

      const cartExists = !!cartId.value

      const { mutate, onDone } = useMutation<CartLinesAddMutation>(
        CartLinesAddDocument,
        { clientId: 'shopify' },
      )

      onDone(({ data }) => {
        if (data?.cartLinesAdd?.cart) {
          const { cart } = data.cartLinesAdd

          cart.lines.nodes.forEach((line) => {
            const productId = shopifyGidToNumericId(line.merchandise.product.id)
            const variantId = shopifyGidToNumericId(line.merchandise.id)
            const name = `${line.merchandise.product.title} | ${line.merchandise.title}`
            const collection =
              line.merchandise.product.collections.nodes[0].title
            const quantity = line.quantity
            const price = line.cost.amountPerQuantity.amount
            const currency = line.cost.amountPerQuantity.currencyCode

            $fb.track('AddToCart', {
              content_ids: [productId],
              content_category: collection,
              content_name: name,
              content_type: 'product',
              contents: [{ id: variantId, quantity }],
              currency,
              value: parseFloat(price),
            })

            useTrackEvent('add_to_cart', {
              name,
              product_id: productId,
              variant_id: variantId,
              collection,
              quantity,
              currency,
              value: price,
            })
          })

          trackHyrosCart(data.cartLinesAdd.cart, !cartExists)
        }

        isLoading.value = false
        showCart.value = true
      })

      if (cartExists) {
        return mutate({ cartId: cartId.value, lines })
      } else {
        return createCart().then(() => mutate({ cartId: cartId.value, lines }))
      }
    }

    const removeCartLines = (
      lineIds: CartLinesRemoveMutationVariables['lineIds'],
    ) => {
      isLoading.value = true

      const { mutate, onDone } = useMutation<CartLinesRemoveMutation>(
        CartLinesRemoveDocument,
        { clientId: 'shopify' },
      )

      onDone(({ data }) => {
        if (data?.cartLinesRemove?.cart) {
          trackHyrosCart(data.cartLinesRemove.cart)
        }

        isLoading.value = false
      })

      return mutate({ cartId: cartId.value, lineIds })
    }

    const trackHyrosCart = (cart: CartFragment, isNewCart = false) => {
      if (cart.lines.nodes.length) {
        return $fetch('/api/hyros/carts', {
          method: isNewCart ? 'POST' : 'PUT',
          body: {
            cartId: cartId.value,
            currency: 'AUD',
            items: cart.lines.nodes.map(shopifyCartLinesToHyrosCartLines),
            ...(accountStore.customer && {
              email: accountStore.customer.email,
              firstName: accountStore.customer.firstName,
              lastName: accountStore.customer.lastName,
            }),
          },
        }).catch((err) => {
          // eslint-disable-next-line no-console
          console.warn(err)
        })
      }
    }

    const updateCartLines = (
      lines: CartLinesUpdateMutationVariables['lines'],
    ) => {
      isLoading.value = true

      const { mutate, onDone } = useMutation<CartLinesUpdateMutation>(
        CartLinesUpdateDocument,
        { clientId: 'shopify' },
      )

      onDone(() => {
        isLoading.value = false
      })

      return mutate({ cartId: cartId.value, lines })
    }

    const updateCartBuyerIdentity = () => {
      isLoading.value = true

      const { mutate, onDone } = useMutation<CartBuyerIdentityUpdateMutation>(
        CartBuyerIdentityUpdateDocument,
        { clientId: 'shopify' },
      )

      onDone(({ data }) => {
        if (data?.cartBuyerIdentityUpdate?.cart) {
          trackHyrosCart(data.cartBuyerIdentityUpdate.cart)
        }

        isLoading.value = false
      })

      return mutate({
        cartId: cartId.value,
        buyerIdentity: {
          countryCode: 'AU',
          ...(accountStore.customerAccessToken && {
            customerAccessToken: accountStore.customerAccessToken,
          }),
        },
      })
    }

    return {
      cartId,
      isLoading,
      showCart,
      createCart,
      updateCartBuyerIdentity,
      addCartLines,
      removeCartLines,
      updateCartLines,
    }
  },
  {
    persist: {
      paths: ['cartId'],
    },
  },
)
