<template>
  <button :disabled="props.disabled" :type="props.type" class="btn">
    <slot />
  </button>
</template>

<script lang="ts" setup>
interface BaseButtonProps {
  disabled?: boolean
  type?: 'button' | 'submit'
}

const props = withDefaults(defineProps<BaseButtonProps>(), {
  disabled: false,
  type: 'button',
})
</script>
