import gql from 'graphql-tag';
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  description
  id
  sizes {
    xs {
      url
      width
    }
    sm {
      url
      width
    }
    md {
      url
      width
    }
    lg {
      url
      width
    }
    xl {
      url
      width
    }
    xxl {
      url
      width
    }
    xxxl {
      url
      width
    }
    opengraph {
      url
    }
  }
  url
  width
}
    `;
export const PageFragmentDoc = gql`
    fragment Page on Page {
  id
  meta {
    description
    image {
      ...Image
    }
    title
  }
  title
}
    ${ImageFragmentDoc}`;
export const VideoFragmentDoc = gql`
    fragment Video on Video {
  id
  url
}
    `;
export const ComparisonBlockFragmentDoc = gql`
    fragment ComparisonBlock on ComparisonBlock {
  blockType
  heading
  text
  competitor {
    name
    image {
      ...Image
    }
  }
  rows {
    nimbus {
      text
      footnote
    }
    competitor {
      text
      footnote
    }
  }
}
    ${ImageFragmentDoc}`;
export const ImageTextGridBlockFragmentDoc = gql`
    fragment ImageTextGridBlock on ImageTextGridBlock {
  blockType
  heading
  items {
    heading
    text
    image {
      ...Image
    }
  }
}
    ${ImageFragmentDoc}`;
export const IngredientsNutritionBlockFragmentDoc = gql`
    fragment IngredientsNutritionBlock on IngredientsNutritionBlock {
  blockType
  text
  icons {
    image {
      ...Image
    }
  }
  ingredients {
    name
    description
    image {
      ...Image
    }
  }
  servings {
    servingsPerPack
    servingSize
  }
  nutritionTable {
    label
    perServingQuantity
    perPreparedQuantity
    subRows {
      label
      perServingQuantity
      perPreparedQuantity
    }
  }
}
    ${ImageFragmentDoc}`;
export const ListImagesBlockFragmentDoc = gql`
    fragment ListImagesBlock on ListImagesBlock {
  blockType
  heading
  text
  list {
    item
  }
  images {
    image {
      ...Image
    }
  }
}
    ${ImageFragmentDoc}`;
export const ShopifyCollectionBlockFragmentDoc = gql`
    fragment ShopifyCollectionBlock on ShopifyCollectionBlock {
  blockType
  collectionHeading: heading
  handle
}
    `;
export const UpdateInstagramApiDocument = gql`
    mutation UpdateInstagramApi($userId: Float!, $accessToken: String!) {
  updateInstagramApi(data: {userId: $userId, accessToken: $accessToken}) {
    userId
    accessToken
  }
}
    `;
export const GetGlobalsDocument = gql`
    query GetGlobals {
  Redirect {
    legacy {
      redirects {
        slug
        target
      }
    }
    qrCodes {
      redirects {
        slug
        target
      }
    }
  }
  Site {
    banner {
      isEnabled
      text
      url
    }
    logIn {
      image {
        ...Image
      }
    }
    meta {
      title
      description
      image {
        ...Image
      }
    }
    newsletter {
      klaviyoCustomSourceValue
    }
    social {
      instagramHandle
    }
  }
}
    ${ImageFragmentDoc}`;
export const GetImageDocument = gql`
    query GetImage($id: String!) {
  Image(id: $id) {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const GetPageDocument = gql`
    query GetPage($slug: String!) {
  Pages(where: {slug: {equals: $slug}}) {
    docs {
      ...Page
      id
      contentFields {
        header {
          heading
          text
        }
        content
      }
      faqsFields {
        header {
          heading
          text
        }
        sections {
          heading
          questions {
            question
            answer
          }
        }
      }
      homeFields {
        header {
          background {
            image {
              ...Image
            }
            video {
              ...Video
            }
          }
          heading
          text
        }
        intro {
          text
          images {
            image {
              ...Image
            }
          }
          ticker {
            text
          }
        }
        howItWorks {
          items {
            heading
            text
            image {
              ...Image
            }
          }
        }
      }
      missionFields {
        header {
          heading
          text
          cinemagraph {
            frames {
              image {
                ...Image
              }
            }
            framesPerSecond
          }
        }
        goals {
          heading
          items {
            heading
            text
            image {
              ...Image
            }
          }
        }
      }
    }
  }
}
    ${PageFragmentDoc}
${ImageFragmentDoc}
${VideoFragmentDoc}`;
export const GetProductDocument = gql`
    query GetProduct($shopifyId: String!) {
  Products(where: {shopify__shopifyId: {equals: $shopifyId}}) {
    docs {
      blocks {
        ...ComparisonBlock
        ...ImageTextGridBlock
        ...IngredientsNutritionBlock
        ...ListImagesBlock
        ...ShopifyCollectionBlock
      }
      id
      meta {
        description
        image {
          ...Image
        }
        title
      }
      shopify {
        collectionHandle
      }
    }
  }
}
    ${ComparisonBlockFragmentDoc}
${ImageTextGridBlockFragmentDoc}
${IngredientsNutritionBlockFragmentDoc}
${ListImagesBlockFragmentDoc}
${ShopifyCollectionBlockFragmentDoc}
${ImageFragmentDoc}`;
export const GetPromoDocument = gql`
    query GetPromo($slug: String!) {
  Promos(where: {slug: {equals: $slug}}) {
    docs {
      blocks {
        ...ComparisonBlock
        ...ImageTextGridBlock
        ...IngredientsNutritionBlock
        ...ListImagesBlock
        ...ShopifyCollectionBlock
      }
      description
      heading
      id
      image {
        ...Image
      }
      isEnabled
      klaviyoCustomSourceValue
      meta {
        description
        image {
          ...Image
        }
        title
      }
      terms {
        text
      }
      title
    }
  }
}
    ${ComparisonBlockFragmentDoc}
${ImageTextGridBlockFragmentDoc}
${IngredientsNutritionBlockFragmentDoc}
${ListImagesBlockFragmentDoc}
${ShopifyCollectionBlockFragmentDoc}
${ImageFragmentDoc}`;