<template>
  <NuxtLayout class="font-body">
    <div class="flex h-screen flex-col bg-cream-light">
      <div class="nav-bar-height flex-none" />
      <div class="flex flex-grow items-center justify-center">
        <div class="flex flex-col items-center space-y-10">
          <AnimationFloatingSimple class="w-[160px]" />
          <p class="type-heading-3" v-text="message" />
          
          <div>
            <BaseButton class="btn-lg btn-black" @click="handleError"
              >Take me home</BaseButton
            >
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { H3Error } from 'h3'

interface Props {
  error: H3Error
}
const props = defineProps<Props>()
const is404 = computed(() => props.error.statusCode === 404)
const message = is404.value
  ? 'No trace of intelligent life up here…'
  : 'Something went wrong…'

const handleError = () => clearError({ redirect: '/' })
</script>
