const _DEFAULT = "#3b82f6"
const _white = "#ffffff"
const _black = "#000000"
const _transparent = "rgba(0, 0, 0, 0)"
const _current = "currentColor"
const _cream = {"light":"#fbfaf3","dark":"#f0ede4"}
const _green = "#dbf28f"
const _orange = "#ff8361"
const _purple = "#dcd0f1"
const config = { "DEFAULT": _DEFAULT, "white": _white, "black": _black, "transparent": _transparent, "current": _current, "cream": _cream, "green": _green, "orange": _orange, "purple": _purple,  }
export { config as default, _DEFAULT, _white, _black, _transparent, _current, _cream, _green, _orange, _purple }