import { defineStore } from 'pinia'

import type {
  CustomerAccessToken,
  CustomerAccessTokenCreateMutation,
  CustomerAccessTokenCreateMutationVariables,
  CustomerActivateByUrlMutation,
  CustomerAddressCreateMutation,
  CustomerAddressUpdateMutation,
  CustomerCreateMutation,
  CustomerCreateMutationVariables,
  CustomerDefaultAddressUpdateMutation,
  CustomerFragment,
  CustomerRecoverMutation,
  CustomerResetByUrlMutation,
  CustomerUpdateInput,
  CustomerUpdateMutation,
  GetCustomerQuery,
  MailingAddressInput,
} from '@/shopify-storefront'

import {
  CustomerAccessTokenCreateDocument,
  CustomerActivateByUrlDocument,
  CustomerAddressCreateDocument,
  CustomerAddressUpdateDocument,
  CustomerCreateDocument,
  CustomerDefaultAddressUpdateDocument,
  CustomerRecoverDocument,
  CustomerResetByUrlDocument,
  CustomerUpdateDocument,
  GetCustomerDocument,
} from '@/graphql/shopify/storefront'

export const useAccountStore = defineStore('account', () => {
  const config = useRuntimeConfig()
  const cartStore = useCartStore()

  const customer = ref<CustomerFragment | null>(null)
  const customerAccessToken = ref('')
  const isLoading = ref(false)

  const createCustomer = (variables: CustomerCreateMutationVariables) => {
    isLoading.value = true

    const { mutate, onDone } = useMutation<CustomerCreateMutation>(
      CustomerCreateDocument,
      { clientId: 'shopify' },
    )

    onDone(({ data }) => {
      customer.value = data?.customerCreate?.customer || null
      isLoading.value = false
    })

    return mutate(variables)
  }

  const activateCustomer = (activationUrl: string, password: string) => {
    isLoading.value = true

    const { mutate, onDone } = useMutation<CustomerActivateByUrlMutation>(
      CustomerActivateByUrlDocument,
      { clientId: 'shopify' },
    )

    onDone(({ data }) => {
      customer.value = data?.customerActivateByUrl?.customer || null

      if (data?.customerActivateByUrl?.customerAccessToken) {
        saveCustomerAccessTokenCookie(
          data.customerActivateByUrl.customerAccessToken,
        )
      }

      isLoading.value = false
    })

    return mutate({ activationUrl, password })
  }

  const getCustomer = () => {
    return useAsyncQuery<GetCustomerQuery>({
      query: GetCustomerDocument,
      variables: { customerAccessToken: customerAccessToken.value },
      clientId: 'shopify',
    }).then(async ({ data }) => {
      customer.value = data.value?.customer || null

      if (!customer.value) {
        return logOut()
      }

      if (cartStore.cartId) {
        await cartStore.updateCartBuyerIdentity()
      }

      identifyUser()
    })
  }

  const updateCustomer = (input: CustomerUpdateInput) => {
    isLoading.value = true

    const { mutate, onDone } = useMutation<CustomerUpdateMutation>(
      CustomerUpdateDocument,
      { clientId: 'shopify' },
    )

    onDone(({ data }) => {
      customer.value = data?.customerUpdate?.customer || null
      isLoading.value = false
    })

    return mutate({
      customer: input,
      customerAccessToken: customerAccessToken.value,
    })
  }

  const recoverCustomer = (email: string) => {
    isLoading.value = true

    const { mutate, onDone, onError } = useMutation<CustomerRecoverMutation>(
      CustomerRecoverDocument,
      { clientId: 'shopify' },
    )

    onDone(() => {
      isLoading.value = false
    })

    onError(() => {
      isLoading.value = false
    })

    return mutate({ email })
  }

  const resetPassword = (password: string, resetUrl: string) => {
    isLoading.value = true

    const { mutate, onDone } = useMutation<CustomerResetByUrlMutation>(
      CustomerResetByUrlDocument,
      { clientId: 'shopify' },
    )

    onDone(({ data }) => {
      if (data?.customerResetByUrl?.customerAccessToken) {
        saveCustomerAccessTokenCookie(
          data.customerResetByUrl.customerAccessToken,
        )
      }

      isLoading.value = false
    })

    return mutate({ password, resetUrl })
  }

  const createCustomerAddress = (address: MailingAddressInput) => {
    isLoading.value = true

    const { mutate, onDone } = useMutation<CustomerAddressCreateMutation>(
      CustomerAddressCreateDocument,
      { clientId: 'shopify' },
    )

    onDone(() => {
      isLoading.value = false
    })

    return mutate({
      address,
      customerAccessToken: customerAccessToken.value,
    })
  }

  const updateCustomerAddress = (id: string, address: MailingAddressInput) => {
    isLoading.value = true

    const { mutate, onDone } = useMutation<CustomerAddressUpdateMutation>(
      CustomerAddressUpdateDocument,
      { clientId: 'shopify' },
    )

    onDone(() => {
      isLoading.value = false
    })

    return mutate({
      address,
      customerAccessToken: customerAccessToken.value,
      id,
    })
  }

  const updateCustomerDefaultAddress = (id: string) => {
    isLoading.value = true

    const { mutate, onDone } =
      useMutation<CustomerDefaultAddressUpdateMutation>(
        CustomerDefaultAddressUpdateDocument,
        { clientId: 'shopify' },
      )

    onDone(() => {
      isLoading.value = false
    })

    return mutate({
      addressId: id,
      customerAccessToken: customerAccessToken.value,
    })
  }

  const createCustomerAccessToken = (
    variables: CustomerAccessTokenCreateMutationVariables,
  ) => {
    isLoading.value = true

    const { mutate, onDone } = useMutation<CustomerAccessTokenCreateMutation>(
      CustomerAccessTokenCreateDocument,
      { clientId: 'shopify' },
    )

    onDone(({ data }) => {
      if (data?.customerAccessTokenCreate?.customerAccessToken) {
        saveCustomerAccessTokenCookie(
          data.customerAccessTokenCreate.customerAccessToken,
        )
      }

      isLoading.value = false
    })

    return mutate(variables)
  }

  const loadCustomerAccessTokenCookie = () => {
    customerAccessToken.value =
      useCookie(config.public.shopify.customerAccessTokenCookieName).value ?? ''

    if (customerAccessToken.value) {
      return getCustomer()
    }
  }

  const saveCustomerAccessTokenCookie = ({
    accessToken,
    expiresAt,
  }: CustomerAccessToken) => {
    const cookie = useCookie(
      config.public.shopify.customerAccessTokenCookieName,
      {
        path: '/',
        expires: new Date(expiresAt),
      },
    )

    customerAccessToken.value = cookie.value = accessToken
  }

  const unsetCustomerAccessTokenCookie = () => {
    if (import.meta.client) {
      const cookie = useCookie(
        config.public.shopify.customerAccessTokenCookieName,
      )

      cookie.value = customer.value = null
    }
  }

  const identifyUser = () => {
    if (import.meta.client) {
      const { $fb, $klaviyo, $sentry } = useNuxtApp()

      const userData: MetaPixelUserData | null = customer.value
        ? {
            external_id: shopifyGidToNumericId(customer.value.id),
            em: customer.value.email,
            fn: customer.value.firstName,
            ln: customer.value.lastName,
          }
        : null

      $fb.setUserData(userData)
      $fb.enable()

      $klaviyo.push([
        'identify',
        customer.value
          ? {
              $email: customer.value.email,
              $first_name: customer.value.firstName,
              $last_name: customer.value.lastName,
            }
          : {},
      ])

      $sentry.setUser(
        customer.value
          ? {
              id: customer.value.id,
              username: [
                customer.value.firstName,
                customer.value.lastName,
              ].join(' '),
              email: customer.value.email!,
            }
          : {},
      )
    }
  }

  const logIn = (variables: CustomerAccessTokenCreateMutationVariables) => {
    return createCustomerAccessToken(variables)
  }

  const logOut = async () => {
    await $fetch('/api/log-out')
    unsetCustomerAccessTokenCookie()
    identifyUser()
  }

  return {
    customer,
    customerAccessToken,
    isLoading,
    createCustomer,
    activateCustomer,
    getCustomer,
    updateCustomer,
    recoverCustomer,
    resetPassword,
    createCustomerAddress,
    updateCustomerAddress,
    updateCustomerDefaultAddress,
    createCustomerAccessToken,
    loadCustomerAccessTokenCookie,
    saveCustomerAccessTokenCookie,
    unsetCustomerAccessTokenCookie,
    identifyUser,
    logIn,
    logOut,
  }
})
