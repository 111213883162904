import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin(({ $router, vueApp }) => {
  const config = useRuntimeConfig()

  Sentry.init({
    enabled: !config.public.isDev,
    app: vueApp,
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
    debug: config.public.isDev,
    dsn: config.public.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [config.public.siteUrl],
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          $router as Router,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  const { setContext, setUser, setTag, addBreadcrumb, captureException } =
    Sentry

  return {
    provide: {
      sentry: {
        setContext,
        setUser,
        setTag,
        addBreadcrumb,
        captureException,
      },
    },
  }
})
