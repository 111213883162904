import FloatingVue from 'floating-vue'

export default defineNuxtPlugin(({ vueApp }) => {
  // TODO: Use nuxt module export
  vueApp.use(FloatingVue, {
    themes: {
      black: { $extend: 'tooltip' },
      white: { $extend: 'tooltip' },
    },
  })
})
