<template>
  <div class="pointer-events-none relative">
    <div class="rotation-animation">
      <img
        src="/img/illustration-floating-fg.png"
        class="floating-animation h-auto w-full"
      />
    </div>
    <img
      src="/img/illustration-floating-bg.png"
      class="shadow-animation absolute inset-0 h-full w-full"
    />
  </div>
</template>

<style lang="postcss" scoped>
.rotation-animation {
  animation: rotation 7500ms ease-in-out infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}

.floating-animation {
  animation: float 5000ms ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0px);
  }
}

.shadow-animation {
  animation: shadow 5000ms ease-in-out infinite;
}

@keyframes shadow {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.75);
  }
  100% {
    transform: scaleX(1);
  }
}
</style>
