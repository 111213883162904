import revive_payload_client_6wOvRI2caz from "/var/www/nimbus-production/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_rfjkghc6cadheiphnkcxo73rnq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_D8T0NcIQCD from "/var/www/nimbus-production/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_rfjkghc6cadheiphnkcxo73rnq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OvMS5lFbGT from "/var/www/nimbus-production/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_rfjkghc6cadheiphnkcxo73rnq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_32IWYBr4c5 from "/var/www/nimbus-production/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sass@1.69._x777uyadvvii676jicz5bm2r6i/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_5e7d65H0eQ from "/var/www/nimbus-production/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_rfjkghc6cadheiphnkcxo73rnq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rJKHSwnpMd from "/var/www/nimbus-production/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_rfjkghc6cadheiphnkcxo73rnq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gqITD0aDh5 from "/var/www/nimbus-production/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_rfjkghc6cadheiphnkcxo73rnq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TUsHZhadYv from "/var/www/nimbus-production/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_rfjkghc6cadheiphnkcxo73rnq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_B21mZPQATl from "/var/www/nimbus-production/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.37_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/nimbus-production/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_gcFJSfMLyy from "/var/www/nimbus-production/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_rfjkghc6cadheiphnkcxo73rnq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wY4F1ngFbS from "/var/www/nimbus-production/node_modules/.pnpm/nuxt3-meta-pixel@1.0.9_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_client_0Jea19PIge from "/var/www/nimbus-production/node_modules/.pnpm/nuxt-gtag@1.2.1_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import titles_KlyGhGBRLQ from "/var/www/nimbus-production/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0__zntias5kfotaydsc5yslyo2kiu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_YIwM8xLlps from "/var/www/nimbus-production/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sass@1._grw263d6ngulnsnk5svfwir5we/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_VcSEBIjp9y from "/var/www/nimbus-production/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sass@1._grw263d6ngulnsnk5svfwir5we/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_NXu1OTo7YL from "/var/www/nimbus-production/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_@types+react@18.3.3_magicast@0.3.4_react-dom@18.3.1_rollup@4.20_el74jkh43znwaz5sq4damkhvt4/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_lUvY3MnobP from "/var/www/nimbus-production/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typesc_ni56l37fty6oqqxgtsmogcbup4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import anime_client_02GKJVROBY from "/var/www/nimbus-production/apps/nuxt/plugins/anime.client.ts";
import floating_vue_BiOD74u9sH from "/var/www/nimbus-production/apps/nuxt/plugins/floating-vue.ts";
import helpers_mgWmzbOuLB from "/var/www/nimbus-production/apps/nuxt/plugins/helpers.ts";
import klaviyo_client_waKFY8fg2y from "/var/www/nimbus-production/apps/nuxt/plugins/klaviyo.client.ts";
import sentry_client_shVUlIjFLk from "/var/www/nimbus-production/apps/nuxt/plugins/sentry.client.ts";
import serialise_zPteRRya5A from "/var/www/nimbus-production/apps/nuxt/plugins/serialise.ts";
import vue_select_8ji40gexSh from "/var/www/nimbus-production/apps/nuxt/plugins/vue-select.ts";
import defaults_jrxTR6BNBk from "/var/www/nimbus-production/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0__zntias5kfotaydsc5yslyo2kiu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_6wOvRI2caz,
  unhead_D8T0NcIQCD,
  router_OvMS5lFbGT,
  _0_siteConfig_32IWYBr4c5,
  payload_client_5e7d65H0eQ,
  navigation_repaint_client_rJKHSwnpMd,
  check_outdated_build_client_gqITD0aDh5,
  chunk_reload_client_TUsHZhadYv,
  plugin_vue3_B21mZPQATl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gcFJSfMLyy,
  plugin_wY4F1ngFbS,
  plugin_client_0Jea19PIge,
  titles_KlyGhGBRLQ,
  siteConfig_YIwM8xLlps,
  inferSeoMetaPlugin_VcSEBIjp9y,
  plugin_NXu1OTo7YL,
  plugin_lUvY3MnobP,
  anime_client_02GKJVROBY,
  floating_vue_BiOD74u9sH,
  helpers_mgWmzbOuLB,
  klaviyo_client_waKFY8fg2y,
  sentry_client_shVUlIjFLk,
  serialise_zPteRRya5A,
  vue_select_8ji40gexSh,
  defaults_jrxTR6BNBk
]